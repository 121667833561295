import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import KobleRightChoice from '../components/koble/KobleRightChoice'
import HTMLContentContainer from '../components/koble/HTMLContentContainer'

// eslint-disable-next-line
export const TermsOfServicePageTemplate = ({
  kobleRightChoice,
  content,
  title
}) => {
  return (
    <div>
      <HTMLContentContainer privacyPolicyContent={content} title={title} />
      <div className="divider"></div>
      <KobleRightChoice {...kobleRightChoice} />
    </div>
  )
}

TermsOfServicePageTemplate.propTypes = {
  kobleRightChoice: PropTypes.object
}

const TermsOfServicePage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <TermsOfServicePageTemplate
        content={post.html}
        title={post.frontmatter.title}
        kobleRightChoice={post.frontmatter.kobleRightChoice}
      />
    </Layout>
  )
}

TermsOfServicePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
}

export default TermsOfServicePage

export const TermsOfServicePageQuery = graphql`
  query TermsOfServicePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        kobleRightChoice {
          title {
            text
            cssClass
          }
          description
          ctaDetails {
            text
            bgColor
            link
            type
          }
          video {
            extension
            publicURL

            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
  }
`
